import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authGuard } from '@repo/vue/plugins';
import AppLayout from '@/layout/AppLayout.vue';
import AppLanding from '@/layout/AppLanding.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: AppLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: '/entities',
        name: 'entities',
        component: () => import('@/views/main/entities/Entities.vue')
      },
      {
        path: '/process',
        component: () => import('@/layout/AppEntityProcess.vue'),
        children: [
          {
            path: '/files',
            name: 'files',
            component: () => import('@/views/main/files/Files.vue')
          },
          {
            path: '/requests',
            name: 'requests',
            component: () => import('@/views/main/requests/Requests.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: AppLanding,
    children: [
      {
        path: '/callback',
        name: 'callback',
        component: () => import('@/views/common/CallBackView.vue')
      },
      {
        path: '/confirm-user-invitation',
        name: 'confirmUserInvitation',
        component: () => import('@/views/common/ConfirmUserInvitation.vue')
      },
      {
        path: '/registration-completed',
        name: 'registrationComplete',
        component: () => import('@/views/common/RegistrationComplete.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes
});

export default router;
