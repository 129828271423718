import { computed, ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { Entity, User } from '@/core/models';
import { useSessionStorage } from '@vueuse/core';
import { useEntityStore, useUserStore } from '@/stores/index';

export const useRootStore = defineStore('root', () => {
  // stores
  const entityStore = useEntityStore();
  const userStore = useUserStore();

  // state
  const userProfileId = useSessionStorage<string>('userProfileId', null);
  const entityId = useSessionStorage<string>('entityId', null);
  const practiceId = useSessionStorage<string>('practiceId', null);
  const multipleEntities = ref<boolean>(true);

  // getters
  const isEntitySelected = computed(() => !!entityStore.currentEntity?.id);

  const setEntityId = (data: string | undefined) => {
    entityId.value = data;
  };

  const setPracticeId = (data: string | undefined) => {
    practiceId.value = data;
  };

  const setMultipleEntities = (data: boolean) => {
    multipleEntities.value = data;
  };

  const setCurrentUser = (data: User) => {
    userStore.currentUser = data;
  };

  const clearCurrentEntity = () => {
    entityId.value = null;
    entityStore.currentEntity = <Entity>{};
  };
  const clearCurrentUser = () => {
    userProfileId.value = null;
    userStore.currentUser = <User>{};
  };

  const state = {
    entityId,
    userProfileId,
    multipleEntities,
    practiceId
  };
  const getters = {
    isEntitySelected
  };
  const actions = {
    setCurrentUser,
    clearCurrentUser,
    clearCurrentEntity,
    setEntityId,
    setPracticeId,
    setMultipleEntities
  };

  return { ...state, ...getters, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot));
}
