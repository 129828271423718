<script setup lang="ts">
import { onMounted, ref, watch, defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { DateTimeSchema, MessageSchema, NumberSchema } from '@/locales';
import { useLayout } from '@/layout/composables/layout';
import { useEntityStore, usePracticeStore, useRootStore, useUserStore } from '@/stores';
import { useRouter } from 'vue-router';
import { OnClickOutside } from '@vueuse/components';
import { useDxModal } from '@repo/vue/utils';
import { useAuthStore } from '@repo/vue/stores';

// components
const ContactUsModal = defineAsyncComponent(() => import('@/views/common/ContactUsModal.vue'));

// locale
const { t } = useI18n<[MessageSchema, DateTimeSchema, NumberSchema]>();

// utils
const { onProfileSidebarToggle } = useLayout();
const dxModal = useDxModal();

// stores
const userStore = useUserStore();
const rootStore = useRootStore();
const router = useRouter();
const practiceStore = usePracticeStore();
const authStore = useAuthStore();
const entityStore = useEntityStore();

// state
const { isEntitySelected } = storeToRefs(rootStore);
const { currentUser } = storeToRefs(userStore);
const { currentPractice } = storeToRefs(practiceStore);
const { currentEntity } = storeToRefs(entityStore);

// data
const showUserSettings = ref<boolean>(false);

// methods
const onLogout = async () => {
  rootStore.setEntityId(undefined);
  rootStore.setPracticeId(undefined);
  showUserSettings.value = false;
  await authStore.logout();
};

const openContactUs = async () => {
  const modal = await dxModal.push(ContactUsModal, { requestModel: null });
  modal.on('confirm', async (result: boolean) => {});
};

const onConfigButtonClick = async () => {
  await router.push({ name: 'settings' });
};

const returnToMain = async () => {
  rootStore.clearCurrentEntity();
  await router.push('entities');
};

const loadUserData = async () => {
  await userStore.fetchCurrentUser();
};

// watches
watch(
  () => currentUser.value?.id,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await loadUserData();
    }
  },
  { immediate: true }
);

// hooks
onMounted(async () => {
  // remove fetch when I figured out how the store works
  currentUser.value = await userStore.fetchCurrentUser();
});
</script>

<template>
  <div class="top-menu">
    <nav class="navbar navbar-inverse" role="navigation">
      <div class="container-fluid">
        <div class="navbar-header">
          <a class="navbar-brand" :title="t('layout.app_top_menu.back_to_entities')" @click.prevent="returnToMain">
            {{ currentPractice.name }}
          </a>
        </div>
        <ul class="nav navbar-nav navbar-right">
          <li v-if="isEntitySelected">
            <div
              class="entity-detail"
              role="button"
              :title="t('layout.app_top_menu.back_to_entities')"
              @click="returnToMain"
            >
              <div class="tw-text-dx-blue">
                <i class="fa fa-chevron-circle-left"></i>
                &nbsp;{{ t('layout.app_top_menu.back_to_entities') }}
              </div>
            </div>
          </li>
          <li v-if="isEntitySelected">
            <div class="entity-detail divider">|</div>
          </li>
          <li v-if="isEntitySelected">
            <div
              v-if="isEntitySelected"
              id="isClientStatus"
              class="entity-detail"
              role="button"
              :title="currentEntity.name"
            >
              {{ currentEntity.name }}
            </div>
          </li>
          <li>
            <div v-if="isEntitySelected" class="entity-detail divider">|</div>
          </li>
          <li>
            <div>
              <OnClickOutside @trigger="showUserSettings = false">
                <div v-if="currentUser" class="user-btn">
                  <div class="btn-group">
                    <a class="btn" @click="showUserSettings = !showUserSettings">
                      <div class="profile" :title="currentUser.firstName">
                        <strong>{{ currentUser.firstName?.charAt(0) }}</strong>
                      </div>
                    </a>
                    <div class="user-options" :class="{ 'user-options-hidden': !showUserSettings }">
                      <div class="tw-text-left">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
                      <div class="tw-text-left email">
                        {{ currentUser.email }}
                      </div>
                      <div class="actions">
                        <Button
                          severity="secondary"
                          class="tw-float-right tw-text-white tw-bg-neutral-600 tw-border-none tw-h-12"
                          icon="pi pi-sign-out"
                          size="large"
                          :label="t('layout.app_top_menu.logout')"
                          icon-pos="right"
                          @click="onLogout"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </OnClickOutside>
            </div>
          </li>
          <li v-if="currentPractice.displayContactUs">
            <div
              class="entity-detail phone"
              role="button"
              :title="t('layout.app_top_menu.contact_us')"
              @click="openContactUs"
            >
              <div class="tw-text-dx-blue">
                <i class="pi pi-phone tw-text-2xl -tw-mb-[10rem]"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.top-menu {
  .help-wrapper {
    :deep(.p-badge.p-badge-danger.p-badge-dot) {
      position: absolute;
      top: 0.545rem;
      right: 0.727rem;
      width: 0.909rem;
      height: 0.909rem;
    }
  }

  .product-updates {
    position: relative;

    :deep(.p-badge.p-badge-danger.p-badge-dot) {
      position: absolute;
      top: 0.909rem;
      left: 0.636rem;
      width: 0.727rem;
      height: 0.727rem;
    }
  }

  .navbar {
    color: #888;
    margin-bottom: 0;
    padding-top: 1px;
    background: #222;
    border: 0;
    border-radius: 0;
    transition: all 0.6s;
    min-height: 3.818rem;
    .navbar-right {
      margin-right: 0;
    }
  }
  .navbar-brand {
    width: 40rem;
    height: 3.636rem;
    background-size: contain;
    padding: 0 15px 0 15px;
    display: flex;

    align-items: center;
  }
  .backOffice {
    color: var(--dx-blue);
    padding: 0.909rem 0.455rem;
  }
  .entity-detail {
    font-size: 1rem;
    margin: 0.545rem 0.182rem;
    font-weight: bold;
    padding-left: 0.455rem;
    outline: none;
    display: inline-block;
  }
  .phone {
    font-size: 2rem;
    margin: 0.7rem 0.182rem 0rem 0.182rem;
    display: inline-block;
  }
  .hidden-btn {
    color: transparent;
  }
  .hidden-btn:hover {
    color: #fff;
  }
  .client-prac {
    color: #fff;
    background: #263279 !important;
    .divider {
      color: #fff !important;
    }
    .user-btn .profile {
      background: var(--dx-red);
      color: #fff;
    }
  }
  .client-readonly {
    background: var(--dx-red);
    color: var(--dx-white);
    border-radius: 4px;
    padding: 0 0.909rem;
    outline: none;
  }
  .client-demo {
    background: var(--dx-blue);
    color: var(--dx-white);
    border-radius: 4px;
    padding: 0 0.909rem;
    outline: none;
  }
  .balance {
    color: var(--dx-dark-green);
    padding-left: 0.455rem;
  }
  .balance-warning {
    color: var(--dx-orange);
  }
  .balance-error {
    color: var(--dx-red);
  }
  .help-btn {
    .btn {
      background: transparent !important;
      display: flex !important;
      border: none;
      margin: 0.273rem -2.727rem 0px 0px;
    }
    .btn:hover,
    .btn:active,
    .btn:focus {
      border: none;
      color: var(--dx-white);
    }
    .caret {
      margin: 1.182rem 0 0 0.909rem;
    }
    .dropdown-menu {
      position: absolute;
      top: 3.636rem;
      right: -2.727rem;
      left: auto;
      font-size: 1.091rem;
      z-index: 1002;
      border: 1px solid #ccc;
      border-radius: 0;
      .menu-item {
        a {
          color: var(--dx-brown) !important;
          padding: 0.545rem 1.818rem;
        }
      }
      .menu-item-header {
        text-transform: uppercase;
        color: #aaa;
        padding: 0 1.727rem;
      }
      .divider {
        margin: 0.455rem 0;
      }
    }
    .help-wrapper {
      background: var(--dx-blue);
      padding: 0.273rem 0;
      width: 2.182rem;
      height: 2.182rem;
      float: right;
      text-align: center;
      border-radius: 15px;
      color: #fff;
      font-weight: bold;
      font-size: 1.182rem;
    }
    .help-wrapper:hover {
      opacity: 0.8;
    }
  }
  .user-btn {
    width: 3.182rem;
    .btn {
      background: transparent !important;
      display: flex !important;
      border: none;
      margin: 0.273rem -2.727rem 0px 0px;
    }
    .btn:hover,
    .btn:active,
    .btn:focus {
      border: none;
      color: #fff;
    }
    .user-text {
      margin: 0.182rem 0.909rem 0 0;
      font-size: 1.182rem;
    }
    .profile {
      background: #e6e6e6;
      padding: 0.364rem;
      width: 2.182rem;
      height: 2.182rem;
      float: right;
      text-align: center;
      border-radius: 15px;
      color: #000;
      font-weight: bold;
    }

    .caret {
      margin: 0.182rem 0 0 0.909rem;
    }
    .user-options {
      width: 21.364rem;
      height: 10rem;
      padding: 0.455rem 1.364rem;
      background: #333;
      position: absolute;
      top: 3.818rem;
      right: -2.727rem;
      z-index: 1002;
      border: 1px solid #666;
      color: #fff;
      display: block;
      .email {
        font-size: 1rem;
        line-height: 1.364rem;
        color: #999;
      }
      .actions {
        margin-top: 1.364rem;
      }
      .btn {
        margin: auto;
      }
      .btn-user {
        font-size: 1.091rem;
        background: #555 !important;
        width: 7.909rem;
        color: #fff;
        i {
          padding: 0 0 0 1.182rem;
          margin-top: -0.182rem;
          font-size: 1.818rem;
        }
      }
    }
    .user-options-hidden {
      display: none;
    }
  }
  .settings {
    padding-left: 0.7rem;
    padding-top: 0.182rem;
    width: 2.182rem;
    height: 2.182rem;
    text-align: center;
    border-radius: 1.364rem;
    color: #000;
    font-weight: bold;

    a {
      color: var(--dx-blue);
    }
  }
  .icon {
    font-size: 1rem;
  }
  .icon-margin {
    margin-bottom: 0.364rem;
  }
  .profile-icons {
    font-size: 1.909rem;
    position: relative;
    margin-left: 0.909rem;
    bottom: 0.273rem;
  }
  .help-icon {
    margin: auto;
    position: relative;
    top: 0.273rem;
  }
}
.tw-dark .top-menu {
  .navbar {
    background: var(--surface-overlay);
  }
  .dropdown-menu {
    background: var(--surface-e);
    border: 1px solid var(--surface-border);

    .divider {
      background: var(--surface-border);
    }

    .menu-item-header {
      color: var(--text-color);
    }

    .menu-item a {
      color: var(--text-color-secondary) !important;

      &:hover {
        background: var(--surface-d);
      }
    }
  }
}
</style>
