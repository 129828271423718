<script setup lang="ts">
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import AppTopMenu from '@/layout/AppTopMenu.vue';
import AppFooter from '@/layout/AppFooter.vue';
import { useAppStore } from '@repo/vue/stores';
import { usePracticeStore, useRootStore, useUserStore } from '@/stores';
import { useDxEventBus } from '@repo/vue/utils';

// utils
const dxEventBus = useDxEventBus();

// stores
const appStore = useAppStore();
const rootStore = useRootStore();
const userStore = useUserStore();
const practiceStore = usePracticeStore();

// state
const { isIFrame } = storeToRefs(appStore);
const { userProfileId, practiceId } = storeToRefs(rootStore);

// watches
watch(
  userProfileId,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await userStore.fetchCurrentUser();
    }
  },
  { immediate: true }
);

watch(
  practiceId,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await practiceStore.fetchCurrentPractice();
      await userStore.fetchCurrentUser();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="tw-flex tw-flex-col">
    <div>
      <header v-if="!isIFrame" class="header">
        <AppTopMenu />
      </header>
      <main id="content">
        <RouterView />
      </main>
    </div>

    <footer>
      <AppFooter />
    </footer>
  </div>
</template>

<style scoped lang="scss">
html,
body {
  height: 100%;
}

main {
  height: calc(100vh - 6.8rem);
  overflow-y: auto;
}
footer {
  height: 3rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
