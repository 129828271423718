<script setup lang="ts">
import * as _ from 'lodash-es';
import { computed, onMounted, ref, watch } from 'vue';
import { usePrimeVue } from 'primevue/config';
import { storeToRefs } from 'pinia';
import AppProfileSidebar from './AppProfileSidebar.vue';
import { useLayout } from '@/layout/composables/layout';
import { useRoute, useRouter } from 'vue-router';
import { useEntityStore, usePracticeStore, useRootStore, useUserStore } from '@/stores';
import { useAuthStore } from '@repo/vue/stores';
import ProgressSpinner from 'primevue/progressspinner';
import { User } from 'oidc-client-ts';
import { container as WidgetContainerModal } from 'jenesius-vue-modal';
import { Icon } from '@iconify/vue';
import AppMainLayout from './AppMainLayout.vue';

// stores
const rootStore = useRootStore();
const entityStore = useEntityStore();
const userStore = useUserStore();
const router = useRouter();
const authStore = useAuthStore();
const practiceStore = usePracticeStore();

// data
const { currentEntity } = storeToRefs(entityStore);
const { currentUser } = storeToRefs(userStore);
const { userProfileId } = storeToRefs(rootStore);
const { isLoading } = storeToRefs(authStore);
const { currentPractice } = storeToRefs(practiceStore);

const route = useRoute();
const $primevue = usePrimeVue();

const { layoutConfig, layoutState, isSidebarActive, isDarkTheme } = useLayout();
const outsideClickListener = ref<any>(null);
const sidebarRef = ref<any>(null);
const topbarRef = ref<any>(null);

//methods
const changeTheme = (theme: any) => {
  const themeLink = document.getElementById('theme-link');
  if (!themeLink) return;
  const themeHref = themeLink.getAttribute('href');
  if (!themeHref) return;
  const newHref = _.replace(themeHref, layoutConfig.theme.value, theme);

  replaceLink(themeLink, newHref, () => {
    layoutConfig.theme.value = theme;
  });
};

const replaceLink = (linkElement: any, href: any, onComplete: any) => {
  if (!linkElement || !href) {
    return;
  }

  const id = linkElement.getAttribute('id');
  const cloneLinkElement = linkElement.cloneNode(true);

  cloneLinkElement.setAttribute('href', href);
  cloneLinkElement.setAttribute('id', id + '-clone');

  linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

  cloneLinkElement.addEventListener('load', () => {
    linkElement.remove();

    const element = document.getElementById(id); // re-check
    if (element) {
      element.remove();
    }

    cloneLinkElement.setAttribute('id', id);
    if (onComplete) {
      onComplete();
    }
  });
};

const clearRootStoreData = () => {
  const search = window.location.search;
  if ((_.includes(search, 'code=') || _.includes(search, 'error=')) && _.includes(search, 'state=')) {
    rootStore.clearCurrentUser();
    rootStore.clearCurrentEntity();
    console.log('rootStore data reset');
  }
};

const setRootStoreData = (user: User) => {
  rootStore.$patch({
    userProfileId: user.profile?.sub
  });

  console.log('rootStore data saved');
};

authStore.$subscribe(
  (mutation, state) => {
    const user = state.user;
    if (user?.access_token) {
      if (user && user.access_token) {
        clearRootStoreData();
        setRootStoreData(user);
        //setEventStoreData(user);
      }
    }
  },
  { immediate: true }
);

watch(
  userProfileId,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      console.log(currentUser.value);
    }
  },
  { immediate: true }
);

//hooks
onMounted(async () => {
  if (route.query.entityId) {
    rootStore.setEntityId(route.query.entityId.toString());
  }
  if (route.query.practiceId) {
    rootStore.setPracticeId(route.query.practiceId.toString());
  }

  currentPractice.value = await practiceStore.fetchCurrentPractice();
  changeTheme(currentPractice.value.theme);
  if (currentPractice.value?.id) {
    rootStore.setPracticeId(currentPractice.value.id.toString());
  }

  currentEntity.value = await entityStore.fetchCurrentEntity();
  currentUser.value = await userStore.fetchCurrentUser();

  console.log(route.path);
  if (currentEntity.value?.id && _.isEqual(_.toLower(route.path), '/files')) {
    const pathRoute = { name: 'files', query: {} as any };

    if (route.query.openPath) pathRoute.query.openPath = route.query.openPath;

    if (route.query.openFile) pathRoute.query.openFile = route.query.openFile;

    await router.push(pathRoute);
  } else if (currentEntity.value?.id && _.isEqual(_.toLower(route.path), '/requests')) {
  } else {
    await router.push({ name: 'entities' });
  }
});

const containerClass = computed(() => {
  return {
    'layout-light': layoutConfig.colorScheme.value === 'light',
    'layout-dim': layoutConfig.colorScheme.value === 'dim',
    'layout-dark': layoutConfig.colorScheme.value === 'dark',
    'layout-colorscheme-menu': layoutConfig.menuTheme.value === 'colorScheme',
    'layout-primarycolor-menu': layoutConfig.menuTheme.value === 'primaryColor',
    'layout-transparent-menu': layoutConfig.menuTheme.value === 'transparent',
    'layout-overlay': layoutConfig.menuMode.value === 'overlay',
    'layout-static': layoutConfig.menuMode.value === 'static',
    'layout-slim': layoutConfig.menuMode.value === 'slim',
    'layout-slim-plus': layoutConfig.menuMode.value === 'slim-plus',
    'layout-horizontal': layoutConfig.menuMode.value === 'horizontal',
    'layout-reveal': layoutConfig.menuMode.value === 'reveal',
    'layout-drawer': layoutConfig.menuMode.value === 'drawer',
    'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive.value,
    'layout-mobile-active': layoutState.staticMenuMobileActive.value,
    'p-ripple-disabled': $primevue.config.ripple === false,
    'layout-sidebar-active': layoutState.sidebarActive.value,
    'layout-sidebar-anchored': layoutState.anchored.value
  };
});
</script>

<template>
  <div :class="['layout-container', { ...containerClass }]">
    <!-- <AppSidebar ref="sidebarRef" /> -->

    <template v-if="isLoading">
      <div class="tw-flex tw-h-screen">
        <div class="tw-m-auto">
          <ProgressSpinner />
        </div>
      </div>
    </template>
    <template v-else>
      <AppMainLayout> </AppMainLayout>

      <AppProfileSidebar />
      <!-- <AppConfig /> -->
      <Toast />
      <ConfirmDialog>
        <template #message="slotProps">
          <Icon icon="line-md:confirm" :class="slotProps.message.icon" />
          <span class="p-confirm-dialog-message" v-html="slotProps.message.message"></span>
        </template>
      </ConfirmDialog>
      <DynamicDialog />
      <WidgetContainerModal />
      <div class="layout-mask"></div>
    </template>
  </div>
</template>
