import { createAuth, createLoader, createPrimeVue } from '@repo/vue/plugins';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import PiniaLogger from 'pinia-logger';
import messages from '@intlify/unplugin-vue-i18n/messages';
import router from '@/router';
import { AuthConfig } from '@repo/core/security';
import { createSetup } from '@/plugins/app';

import '@/assets/styles.scss';

const pinia = createPinia();
pinia.use(
  PiniaLogger({
    expanded: false,
    showDuration: true,
    showTime: true,
    logErrors: true,
    showPineapple: true,
    showStoreName: true,
    disabled: import.meta.env.MODE === 'production'
  })
);

const i18n = createI18n({
  locale: 'en-ZA',
  messages
});

const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(i18n);
app.use(createPrimeVue());
app.use(createLoader());
app.use(createAuth(AuthConfig));
app.use(createSetup());

app.mount('#app');
